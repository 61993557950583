// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cloths-index-js": () => import("./../src/pages/cloths/index.js" /* webpackChunkName: "component---src-pages-cloths-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-gallery-index-js": () => import("./../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-garments-index-js": () => import("./../src/pages/garments/index.js" /* webpackChunkName: "component---src-pages-garments-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-process-index-js": () => import("./../src/pages/process/index.js" /* webpackChunkName: "component---src-pages-process-index-js" */),
  "component---src-pages-team-index-js": () => import("./../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-gallery-index-js": () => import("./../src/templates/gallery/index.js" /* webpackChunkName: "component---src-templates-gallery-index-js" */),
  "component---src-templates-garment-index-js": () => import("./../src/templates/garment/index.js" /* webpackChunkName: "component---src-templates-garment-index-js" */),
  "component---src-templates-services-index-js": () => import("./../src/templates/services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */)
}

